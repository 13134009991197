<template>
    <!-- 近一年-业务员 -->
    <div class="salesStatisticsBox salesSalesman">
        <div class="month-data-analysis" ref="inBoxHeight">
            <div class="top-echarts-two">
                <div class="echarts-left echarts-total">
                    <div class="title-box">
                        <h1>销售方量</h1>
                        <span @click="lookMore(3)">查看完整数据</span>
                    </div>
                    <div style="width 100%;height:2.7rem;flex 4" id="contractQuantityChild"></div>
                </div>
                <div class="echarts-right echarts-total">
                    <div class="title-box">
                        <h1>销售金额</h1>
                        <span @click="lookMore(4)">查看完整数据</span>
                    </div>
                    <div style="width 100%;height:2.7rem;flex 4" id="estimatedSalesChild"></div>
                </div>
            </div>
            <!-- 导出excel -->
            <exportDom :dom-id="'.el-table__fixed-right'" :excel-name="'按业务员近1年销售情况'"></exportDom>
            <div class="table-list">
                <el-table
                    :data="tabListDataName"
                    style="width: 100%"
                    show-summary
                    :summary-method="getSummariesOne"
                    @row-click="tableRowClick"
                >
                    <el-table-column
                        prop="name"
                        label="业务员"
                        fixed
                        width="150"
                    >
                    </el-table-column>
                    <el-table-column
                        v-for="(date, index) in tabListDataDate"
                        :key="index"
                        :label="date"
                    >
                        <el-table-column
                            :prop="date"
                            min-width="120"
                            label="销售方量(方)"
                        >
                            <template #header>
                                销售方量<br>(方)
                            </template>
                            <template slot-scope="scope">
                                <span v-for="(key, idx) in scope.row.list" :key="idx">
                                    <template v-if="key.date === date">
                                        {{ key.xsfl }}
                                    </template>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            :prop="date"
                            min-width="120"
                            label="销售金额(元)"
                        >
                            <template #header>
                                销售金额<br>(元)
                            </template>
                            <template slot-scope="scope">
                                <span v-for="(key, idx) in scope.row.list" :key="idx">
                                    <template v-if="key.date === date">
                                        {{ key.xsje }}
                                    </template>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column
                        prop="total"
                        fixed="right"
                        width="150"
                        label="销售方量小计(方)"
                    >
                        <template #header>
                            销售方量小计<br>(方)
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="htTotal"
                        fixed="right"
                        width="150"
                        label="销售金额小计(元)"
                    >
                        <template #header>
                            销售金额小计<br>(元)
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <alertEcharts v-if="showContract" :alert-status="alertStatus" @closeChildAlert="closeChildFn"></alertEcharts>
        <xsflContrast
            v-if="xsflContrastShow"
            :click-time="clickTime"
            :click-name="clickName"
            @closeChildAlert="closeChildFn"
        ></xsflContrast>
        <totalContrast
            v-if="totalContrastShow"
            :table-click-name="tableClickName"
            :table-click-data="tableClickData"
            @closeChildAlert="closeChildFn"
        ></totalContrast>
    </div>
</template>

<script>
import exportDom from './../export';
import { heckAllCnText } from '../../utils';
import alertEcharts from '../contractQuantit';
// 当月对比
import xsflContrast from './components/flOrJeContrast';
// 个人合计对比
import totalContrast from './components/totalContrast';
export default {
    data() {
        return {
            tabListData: [],
            tabListDataName: [],
            tabListDataList: [],
            tabListDataDate: [],
            tabListEcharts: [],
            tabListEchartsHt: [],
            echartsColor: ['#EA8343', '#3E79C4', '#E6E429', '#E44F4F'],

            showContract: false,
            xsflContrastShow: false,
            clickTime: '',
            clickName: '',

            totalContrastShow: false,
            tableClickName: '',
            tableClickData: {},
            // 销售方量源数据
            xsfllist: {},
            // 销售金额源数据
            xsjelist: {},
        };
    },
    components: {
        alertEcharts,
        xsflContrast,
        totalContrast,
        exportDom,
    },
    mounted() {
        this.processingCenter();
    },
    methods: {
        // 合计方法
        getSummariesOne(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                // 获取与当前行时间相同的数据
                const arr = this.tabListDataList.filter(item => {
                    return column.property === item.date;
                });
                // 对应时间销售方量(方)和销售金额(元)的数值数组 // 对应每一列的数据数组
                // eslint-disable-next-line consistent-return
                let values = arr.map(item => {
                    if (column.label === '销售方量(方)') {return item.xsfl;}
                    if (column.label === '销售金额(元)') {return item.xsje;}
                });

                if (column.label === '合计销售金额(元)') {
                    // eslint-disable-next-line consistent-return
                    values = data.map(item => {
                        if (column.label === '合计销售金额(元)') {
                            return item.htTotal;
                        }
                    });
                }
                if (column.label === '合计销售方量(方)') {
                    // eslint-disable-next-line consistent-return
                    values = data.map(item => {
                        if (column.label === '合计销售方量(方)') {
                            return item.total;
                        }
                    });
                }
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return Math.round(prev + curr * 100);
                        }
                        return prev;
                    }, 0);
                    sums[index] = sums[index] / 100;
                } else {
                    sums[index] = ' ';
                }
            });
            return sums;
        },
        tableRowClick(row, column) {
            if (column.label === '合计销售方量(方)') {
                this.tableClickName = 'hjxsfl';
            } else if (column.label === '合计销售金额(元)') {
                this.tableClickName = 'hjxsje';
            }
            if (this.tableClickName === 'hjxsfl' || this.tableClickName === 'hjxsje') {
                this.tableClickData = row;
                this.totalContrastShow = true;
            }
        },
        // 关闭子组件弹窗
        closeChildFn() {
            this.showContract = false;
            this.xsflContrastShow = false;
            this.totalContrastShow = false;
        },
        // 查看详情
        lookMore(status) {
            // status 3:销售方量  4:销售金额  5:业务员销售销售方量统计   6:业务员销售销售金额统计
            this.alertStatus = status;
            this.showContract = true;
        },
        // 销售方量
        contractQuantityCh(data) {
            const myChart = this.$echarts.init(document.getElementById('contractQuantityChild'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                    formatter: params => {
                        const listKey = params[0].name;
                        let str = '';
                        const pmData = this.xsfllist[listKey];
                        let total = 0;
                        pmData.map((item, index) => {
                            total += item.xsfl;
                            str += item.name + '：' + item.xsfl + '<br/>';
                            if (index === pmData.length - 1) {
                                str += '合计：' + total + '<br/>';
                            }
                        });
                        return str;
                    },
                },
                legend: {
                    data: this.tabListEchartsHt,
                    itemWidth: 10,
                    itemHeight: 10,
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListEcharts,
                        triggerEvent: true,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(个)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(个)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                series: data,
            };
            const that = this;
            myChart.on('click', function (params) {
                that.clickName = 'xsfl';
                if (params.componentType === 'xAxis') {
                    that.clickTime = params.value;
                    that.xsflContrastShow = true;
                } else {
                    that.clickTime = params.name;
                    that.xsflContrastShow = true;
                }
            });
            myChart.setOption(option, true);
        },
        // 销售金额
        estimatedSalesCh(data) {
            const myChart = this.$echarts.init(document.getElementById('estimatedSalesChild'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                    formatter: params => {
                        const listKey = params[0].name;
                        let str = '';
                        const pmData = this.xsjelist[listKey];
                        let total = 0;
                        pmData.map((item, index) => {
                            str += item.name + '：' + item.xsje + '<br/>';
                            total += item.xsje;
                            if (index === pmData.length - 1) {
                                str += '合计：' + total;
                            }
                        });
                        return str;
                    },
                },
                legend: {
                    data: this.tabListEchartsHt,
                    itemWidth: 10,
                    itemHeight: 10,
                },
                grid: {
                    top: '13%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListEcharts,
                        triggerEvent: true,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                series: data,
            };
            const that = this;
            myChart.on('click', function (params) {
                that.clickName = 'xsje';
                if (params.componentType === 'xAxis') {
                    that.clickTime = params.value;
                    that.xsflContrastShow = true;
                } else {
                    that.clickTime = params.name;
                    that.xsflContrastShow = true;
                }
            });
            myChart.setOption(option, true);
        },
        // 处理中心
        processingCenter() {
            this.tabListEcharts = [];
            const month = new Date().getMonth() + 1;
            this.$axios
                .get('/interfaceApi/report/salesanalysis/xsqk/ywy/months?date=&datenum=' + month)
                .then(res => {
                    this.tabListData = res;
                    this.tabListDataList = res.list;
                    this.tabListDataDate = res.date;
                    this.xsfllist = res.xsfllist;
                    this.xsjelist = res.xsjelist;
                    res.date.forEach(item => {
                        const gangName = heckAllCnText(item);
                        this.tabListEcharts.push(gangName);
                    });
                    // 表格渲染
                    this.tabListDataName = res.name.map(item => {
                        let yjxsflTotal = 0;
                        let htslTotal = 0;
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        arr.forEach(item => {
                            yjxsflTotal += Math.round(item.xsfl * 100) / 100;
                            htslTotal += Math.round(item.xsje * 100) / 100;
                        });
                        return {
                            name: item,
                            list: arr,
                            total: yjxsflTotal,
                            htTotal: htslTotal.toFixed(2),
                        };
                    });
                    // 处理图表数据
                    this.tabListEchartsHt = res.topname;
                    this.tabListEchartsHt.push('合计');
                    // 销售方量
                    let htEchartSeriesData = [];
                    const arr = [];
                    for (let i = 0; i < res.xsfllist[Object.keys(res.xsfllist)[0]].length; i++) {
                        arr.push(i);
                    }
                    htEchartSeriesData = arr.map(item => {
                        const htItemListData = [];
                        for (const key in res.xsfllist) {
                            if ( res.xsfllist[key][item]) {
                                htItemListData.push(res.xsfllist[key][item].xsfl);
                            }
                        }
                        const arrName = this.tabListEchartsHt[item];
                        const arrColor = this.echartsColor[item];
                        const htEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            data: htItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return htEchartSeries;
                    });
                    // 销售金额
                    let flEchartSeriesData = [];
                    const xsjeArr = [];
                    for (let i = 0; i < res.xsjelist[Object.keys(res.xsjelist)[0]].length; i++) {
                        xsjeArr.push(i);
                    }
                    flEchartSeriesData = xsjeArr.map((item, index) => {
                        const flItemListData = [];
                        for (const key in res.xsjelist) {
                            if (res.xsjelist[key][item]) {
                                flItemListData.push(res.xsjelist[key][item].xsje);
                            }
                        }
                        const arrName = this.tabListEchartsHt[index];
                        const arrColor = this.echartsColor[index];
                        const flEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            data: flItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return flEchartSeries;
                    });

                    const htTotalData = [];
                    for (const key in res.xsfllist) {
                        let total = 0;
                        res.xsfllist[key].forEach(item => {
                            total += item.xsfl;
                        });
                        htTotalData.push(total);
                    }
                    const htTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: htTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '14',
                            padding: 5,
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    // 销售金额合计
                    const flTotalData = [];
                    for (const key in res.xsjelist) {
                        let total = 0;
                        res.xsjelist[key].forEach(item => {
                            total += item.xsje;
                        });
                        flTotalData.push(total);
                    }
                    const flTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: flTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '14',
                            padding: 5,
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    const htCompleteData = htEchartSeriesData;
                    htCompleteData.push(htTotal);
                    const flCompleteData = flEchartSeriesData;
                    flCompleteData.push(flTotal);
                    setTimeout(() => {
                        // 销售方量图表
                        this.contractQuantityCh(htCompleteData);
                        // 销售金额图表
                        this.estimatedSalesCh(flCompleteData);
                    }, 200);
                })
                .catch(error => {
                    if (error.ErrorCode) {
                        this.$message.error(error.ErrorCode.Message);
                    } else {
                        this.$message.error(error.message);
                    }
                });
        },
    },
};

</script>
<style lang='stylus'>
.salesSalesman
    padding-top .2rem !important
    .month-data-analysis
        height 100%
        .table-list
            height calc(100% - 3.57rem)
            .el-table__fixed-body-wrapper tbody tr td
                &:last-child
                    cursor pointer
                &:nth-last-child(2)
                    cursor pointer
</style>
